
<template>
  <div>
    <dialog-loading v-if="saving"></dialog-loading>
    <card-loading-transparent v-if="loading" :elevation="false" card_color="white" class="pa-10"></card-loading-transparent>
    <div v-if="!loading && main_data" class="pb-8">
      <div class="d-flex justify-end">
        <v-btn v-if="is_readonly" small class="ma-2" color="primary" dark @click="editInfos()"><v-icon left>mdi-pencil</v-icon>Editar</v-btn>
        <v-btn v-if="!is_readonly" small class="ma-2 mr-0" color="error" @click="cancelChanges()"> <v-icon  left>mdi-cancel</v-icon> Cancelar</v-btn>
        <v-btn v-if="!is_readonly" small class="ma-2 mr-0" color="success" @click="saveInfo()"> <v-icon left>mdi-content-save</v-icon> salvar</v-btn>
      </div>
      <v-form class="px-4" ref="formData" v-model="form_valid" :readonly="is_readonly">
        <v-row>
          <v-col cols='12' class="d-flex justify-center">
            <v-hover v-slot="{ hover }">
              <v-avatar :size="$vuetify.breakpoint.smAndUp ? '160' : '120'" :class="{ 'on-hover': hover }" justify="space-between" @click="is_readonly ? ()=>{} : $refs.avatar.click()">
                <img :class="hover && !is_readonly ? 'img-avatar img-avatar-with-opaticy' : 'img-avatar'" :src="image_to_show"/>
                <v-icon v-if="!is_readonly" color="primary" :style="hover ? visible : transparent" size="27">mdi-pencil</v-icon>
              </v-avatar>
            </v-hover>
            <input type="file" accept="image/*" style="display: none" id="avatar" ref="avatar" @change="handleChangeImage()">
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' sm="6" :class="$vuetify.breakpoint.smAndUp ? 'py-3 pb-0' : 'pl-0 py-0'">
            <v-text-field prepend-icon="mdi-information" required v-model="main_data.name" label="Nome" :rules="required" type="text"></v-text-field>
          </v-col>
          <v-col cols='12' sm="6" :class="$vuetify.breakpoint.smAndUp ? 'py-3 pb-0' : 'pl-0 py-0'">
            <v-text-field prepend-icon="mdi-email" required v-model="main_data.email" readonly label="E-mail" :rules="required" type="text"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' sm="6" :class="$vuetify.breakpoint.smAndUp ? 'py-3 pb-0' : 'pl-0 py-0'">
            <v-text-field
              prepend-icon="mdi-phone"
              required v-model="main_data.phone"
              label="Telefone"
              type="text"
              :rules="required"
            ></v-text-field>
          </v-col>
          <v-col cols='12' sm="6" :class="$vuetify.breakpoint.smAndUp ? 'py-3 pb-0' : 'pl-0 py-0'">
            <v-text-field prepend-icon="mdi-city" required v-model="main_data.city" label="Cidade" :rules="required" type="text"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' sm="6" :class="$vuetify.breakpoint.smAndUp ? 'py-3 pb-0' : 'pl-0 py-0'">
            <v-text-field prepend-icon="mdi-city" required v-model="main_data.state" label="Estado" :rules="required" type="text"></v-text-field>
          </v-col>
          <v-col cols='12' sm="6" :class="$vuetify.breakpoint.smAndUp ? 'py-3 pb-0' : 'pl-0 py-0'">
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              v-model="main_data.country" 
              :items="countryes"
              label="Nacionalidade"
              prepend-icon="mdi-city"
              readonly
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' sm="6" :class="$vuetify.breakpoint.smAndUp ? 'pt-3 pb-0' : 'pl-0 py-0'">
            <date-picker
              :read_only="is_readonly"
              label="Data de Nascimento"
              @datePicked="handleDatePicked"
              :rules="required"
              active_picker="YEAR"
              :date="main_data.birthdate"
              :max="$dayjs().subtract(18, 'year').format('YYYY-MM-DD')" 
            ></date-picker>
          </v-col>
          <v-col cols='12' sm="6" :class="$vuetify.breakpoint.smAndUp ? 'pt-3 pb-0' : 'pl-0 py-0'" v-if="user.role.name == 'student'">
            <v-text-field prepend-icon="mdi-link" v-model="main_data.curriculum_link" label="Curriculum Lattes" :rules="requiredUrl" type="text"></v-text-field>
          </v-col>
          <v-col cols='12' class="pt-0 pb-6" v-if="user.role.name == 'student'">
            <input-local-file-with-preview
              v-if="!is_readonly || main_data.curriculum_file_url"
              section_title="Curriculum"
              accepted_file_options="pdf"
              :file_name="main_data.curriculum_file_name"
              :file_url="main_data.curriculum_file_url"
              :required_field="false"
              :disabled="is_readonly"
              @fileToUpload="handleFileToUpload"
              :is_preview_only="!is_readonly"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import {mask} from 'vue-the-mask'
import validations from "@/mixins/validations";

export default {
  name: 'MainData',
  mixins: [ validations ],
  directives: { mask },
  props: {
    preview: {
      type: [Object, String]
    }
  },
  data() {
    return {
      transparent: 'display: none;',
      visible: 'position: absolute; bottom: 0; right: 0;',
      avatar_local_url: null,
      countryes:[
        { value: 'brazilian', text: 'Brasil'},
        { value: 'argentine', text: 'Argentina'},
        { value: 'colombian', text: 'Colombia'},
        { value: 'uruguayan', text: 'Uruguai'},
        { value: 'paraguayan', text: 'Paraguai'},
        { value: 'other', text: 'Outro'},
      ],
      form_valid: false,
      is_readonly: true,
      loading: false,
      main_data: null,
      saving: false,
    }
  },
  mounted () {
    this.setupLocalMainData()
  },
  methods: {
    setupLocalMainData(){
      //this.main_data = null
      this.main_data = {...this.user}
      this.avatar_local_url = this.main_data.avatar_url
    },
    cancelChanges() {
      this.setupLocalMainData()
      this.is_readonly = true
    },
    editInfos(){
      this.is_readonly = false
    },
    saveInfo() {
      if (this.$refs.formData.validate()) {
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          title: `Deseja salvar as modificações?`,
          icon: 'question',
        }).then(r=>{
          if (r.isConfirmed) {
            this.saveUser()
          }
        })
      }else{
        this.addNotification({type: 'error', msg: 'Preencha todos os campos corretamente'})
      }
    },
    saveUser(){
      this.saving = true
      const user = {
        id: this.user.id,
        ...this.main_data
      }
      const formData = new FormData()

      Object.entries(user).forEach(([key, value]) => {
        formData.append('user['+key+']', value || '');
      });
      this.updateUserWithFormData(formData).then(()=>{
        this.setupLocalMainData()
      }).finally(() => {
        this.saving = false
        this.is_readonly = true
      })
    },
    handleFileToUpload(file){
      this.main_data.curriculum_file = file
    },
    handleChangeImage(){
      let file = this.$refs.avatar.files[0]
      let url = window.URL.createObjectURL(file);
      this.avatar_local_url = url
      this.main_data.avatar = file
    },
    handleDatePicked(Nv){
      this.main_data.birthdate = Nv
    },
    ...mapActions({
      updateUserWithFormData: 'User/updateUserWithFormData',
      addNotification: 'Notification/add'
    })
  },
  computed: {
    image_to_show() {
      if(this.main_data.avatar_url && this.avatar_local_url == null){
        return this.main_data.avatar_url
      }else if(this.avatar_local_url){
        return this.avatar_local_url
      }else{
        return 'https://media.istockphoto.com/photos/businessman-silhouette-as-avatar-or-default-profile-picture-picture-id476085198?b=1&k=20&m=476085198&s=170667a&w=0&h=Ct4e1kIOdCOrEgvsQg4A1qeuQv944pPFORUQcaGw4oI='
      }
    },
    ...mapState({
      user: state => state.User.user
    })
  },
}
</script>

<style scoped>
.v-avatar {
  cursor: pointer;
}
.img-avatar {
  transition: opacity .4s ease-in-out;
}
.img-avatar-with-opaticy {
  opacity: 0.6;
}
</style>